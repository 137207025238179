import { FunctionComponent } from 'react';

import Link from 'next/link';

import { Container, Content01 } from '@/atoms';
import { CardBlog, Hero } from '@/molecules';
import { CardsPagination, SliderHero } from '@/organisms';

import { ButtonVariant } from '@/atoms/button/types';
import { Size, Variation } from '@/molecules/card-blog/types';
import { getUrlFromMedia } from '@/utils/utils';
import { ProductBlockShopify } from '@/templates';
import { StylePagination } from '@/organisms/slider-hero/types';

/**
 * ArticleCategory
 */
export const ArticleCategory: FunctionComponent<any> = ({ content }) => {
    const heroPosts = content.views.articles.filter(
        (item: { field_highlighted_category: boolean; }) => item.field_highlighted_category === true
    );
    return (
        <div className="pt-header">
            <Container wrapper wrapperSize="max-w-[790px]">
                <Content01
                    pretitle="ESCUELA DEL JAMÓN"
                    title={content.node.name}
                    typeTitle="h1"
                    position="center"
                />
            </Container>

            <Container wrapper>
                <SliderHero pagination={StylePagination.ARTICLES_PAGINATION} className={`${heroPosts.length > 1 ? "md:mb-[30px]" : ""}`}>
                    {heroPosts.map((post: any, index: number) => (
                        <SliderHero.Slide key={index}>
                            <Hero
                                backgroundImage={getUrlFromMedia(post.field_image)}
                                overlay={0.5}
                                theme="dark"
                                className="!p-10"
                            >
                                <Hero.Title>{post.title}</Hero.Title>
                                {/* {post.body?.summary ? (
                                    <Hero.Description>{post.body.summary}</Hero.Description>
                                ) : null} */}
                                <Hero.Button
                                    href={post.path.alias}
                                    variant={ButtonVariant.DISPLAY}
                                >
                                    Ver post
                                </Hero.Button>
                            </Hero>
                        </SliderHero.Slide>
                    ))}
                </SliderHero>
            </Container>

            <Container wrapper>
                <div style={{ display: 'none' }}>
                    {content.views.articles.map((item: any, index: number) => (
                        <Link key={index} href={item.path.alias}>
                            {item.title}
                        </Link>
                    ))}
                </div>
                <CardsPagination>
                    {content.views.articles.map((post: any, index: number) => {
                        const isPost = post.field_recipe == false

                        return (
                            <CardBlog
                                image={{
                                    src: getUrlFromMedia(post.field_image)
                                }}
                                size={Size.MEDIUM}
                                variation={Variation.DEFAULT}
                                key={index}
                            >
                                {!isPost && (
                                    <CardBlog.Info info={[`${post.field_difficulty}`, `${post.field_servings ? `${post.field_servings} per` : null}`]} />
                                )}
                                <CardBlog.Title>
                                    <Link href={post.path.alias}>
                                        <h3>{post.title}</h3>
                                    </Link>
                                </CardBlog.Title>
                                {/* {isPost && post.body?.summary && (
                                    <CardBlog.Description>
                                        {post.body.summary}
                                    </CardBlog.Description>
                                )} */}
                                <CardBlog.Link href={post.path.alias}>Ver más</CardBlog.Link>
                            </CardBlog>
                        );
                    })}
                </CardsPagination>
            </Container>
            <Container wrapper>
                <ProductBlockShopify
                    shopifyCategory="destacados"
                    shopifyProducts={content.views.products}
                    title="Te puede interesar"
                />
            </Container>
        </div >

    )
};

ArticleCategory.displayName = 'PostCategory';
