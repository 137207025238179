import { NodeProps } from "components/node";
import { Container } from '@/atoms';
import Prose from '@/molecules/prose';
import { useEffect } from "react";
import styles from 'components/molecules/prose/Prose.module.css';

export function LegalPage({ content }: NodeProps) {
    useEffect(() => {
        const settingsLink = document.querySelector('[data-cc-settings="cookie-settings"]');

        if (settingsLink) {
            const observer = new MutationObserver((_, observer) => {
                const triggerButton = document.getElementById("ccc-icon");

                if (triggerButton) {
                    settingsLink.addEventListener("click", (event) => {
                        event.preventDefault();
                        triggerButton.click();
                    });
                    observer.disconnect();
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });

            return () => {
                observer.disconnect();
                settingsLink.removeEventListener("click", () => { });
            };
        }
    }, []);
    return (
        <>
            <Container
                wrapper
                classNameWrapper="pt-header"
                backgroundColor="bg-primary-100"
            >
                <span className="text-primary-600 text-headline text-headline--h2 ">
                    {content.node.title}
                </span>
            </Container>

            <Container
                wrapper
                wrapperSize="max-w-[790px]"
                classNameWrapper="overflow-hidden"
            >
                <div>
                    <Prose className={`${styles['legal-page-text']}`} html={content.node.field_text} />
                </div>
            </Container>

        </>
    );
}